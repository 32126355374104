import React, {useState, useEffect} from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from '../../config';

library.add(...[
    faCheck
]);

export default function Contact({ bg = "blue", wave=true, title = "Junte-se à nossa comunidade", subtitle="...e publique o seu artigo no nosso Blog", capture=false}) {
    const [success, setSuccess] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [listFields, setListfields] = useState({
     //"lista":"5","cliente":"966785","lang":"pt","formid":"7"
    });

    const [message, setMessage]  = useState({});

    useEffect(()=>{
        message.variant && setTimeout(()=> { setMessage({}) }, 10000);
    },[message]);    

    const submitForm = e => {
        setClicked(true)
        e.preventDefault();
        if (listFields.email) {
          setMessage({
            msg: `Spam?? No thanks!`,
            variant: 'error',
          });
          return;
        }
       
        fetch(`${config.url}${config.pathPrefix}.netlify/functions/subscribe`, {
          method: 'POST',
          body: JSON.stringify(listFields),
        })
          .then((data) => {
            setSuccess(true);
            setClicked(false);
            setListfields({});
            setMessage({
              msg: `Obrigado, terá notícias nossas em breve :)`,
              variant: 'success',
            });
          })
          .catch((i) => {
            // unnecessary because Mailchimp only ever
            // returns a 200 status code
            // see below for how to handle errors
            setMessage({
              msg: `Não estava à espera disto, mas estas coisas às vezes acontecem :) `,
              variant: 'error',
            });
          });
      
    }
    
 
    const handleChange = e => {
        setListfields({...listFields, [e.target.name]:e.target.value})
      }



    return (
      <section className={`contact-section ${bg}`} id="contacts">
        {wave && (
          <svg viewBox="0 0 1920 128" className="wave">
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g transform="translate(0.000000, -402.000000)" fill="#f8f9fa">
                <path
                  d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
        )}
        <div className="mb-0">
          <h1 className="text-center">{title}</h1>
          {subtitle && <h4 className="text-center">{subtitle}</h4>}
          <form
            method="post"
            className="contact-form"
            onSubmit={(e) => submitForm(e)}
          >
            <input
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              style={{ display: 'none' }}
            />
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="fname"
              id="fname"
              onChange={handleChange}
              required
            />
            <label htmlFor="EMAIL">Email</label>
            <input
              type="email"
              name="fmail"
              id="fmail"
              onChange={handleChange}
              required
            />

            <button
              type="submit"
              className={`btn btn-primary ${
                capture ? 'btn-cta' : 'btn-white'
              } pad`}
            >
              {clicked ? (
                <>
                  <span className="lds-dual-ring" /> A juntar-me...
                </>
              ) : success ? (
                <>
                  <FontAwesomeIcon icon="check" className="white" /> Agora
                  Pertenço à comunidade
                </>
              ) : (
                <>Juntar-me</>
              )}
            </button>
          </form>
        </div>
        <div className={`snackbar ${message.variant ? message.variant : ''}`}>
          {message.msg}
        </div>
      </section>
    );
}

import React from "react";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { fab} from '@fortawesome/free-brands-svg-icons'

import { library  } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';

const shareButtonsIcons = [
    fab, faLinkedinIn, faTwitter, faFacebookF, faWhatsapp, faEnvelopeOpen
];

library.add(...shareButtonsIcons);

const Share = ({ floating, socialConfig }) => {
    
    return (
      <div className={`post-social ${floating?'floating':''}`}>
        <EmailShareButton
          url={socialConfig.config.url}
          className="button is-outlined is-rounded email mx-2"
          subject={socialConfig.config.title}
          body={''}
        >
          <FontAwesomeIcon icon={['far', 'envelope-open']} />
        </EmailShareButton>
        <FacebookShareButton
          url={socialConfig.config.url}
          quote={`${socialConfig.config.title} ${socialConfig.config.url}`}
          className="button is-outlined is-rounded facebook mx-2"
        >
          <FontAwesomeIcon icon={['fab', 'facebook-f']} />
        </FacebookShareButton>
        <LinkedinShareButton
          url={socialConfig.config.url}
          source={socialConfig.config.url}
          summary={socialConfig.config.title}
          className="button is-outlined is-rounded linkedin mx-2"
        >
          <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
        </LinkedinShareButton>
        <TwitterShareButton
          url={socialConfig.config.url}
          className="button is-outlined is-rounded twitter mx-2"
          title={socialConfig.config.title}
          hashtags={['EMMIM', '@EMMIM'].join(' ')}
          related={''}
        >
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={socialConfig.config.url}
          className="button is-outlined is-rounded whatsapp mx-2"
          title={socialConfig.config.title}
        >
          <FontAwesomeIcon icon={['fab', 'whatsapp']} />
        </WhatsappShareButton>
      </div>
    );
};



export default Share;
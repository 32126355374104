import React from 'react';
import Seo from '../components/Seo';
import { graphql, Link } from 'gatsby';
import moment from 'moment';
import Fade from 'react-reveal/Fade';
import Layout from '../components/Layout';
import Share from '../components/Share';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { GatsbyImage } from 'gatsby-plugin-image'

import Contact from '../components/Contact';
import Footer from '../components/Footer';
import SimilarArticles from '../components/SimilarArticles';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faClock } from '@fortawesome/free-regular-svg-icons';
import 'moment/locale/pt';
import usePreviewData from '../utils/usePreviewData';

import {ReadingTime} from "../utils";

moment.locale('pt');

library.add(...[faHeart, faClock]);
const PostTemplate = ({ data, location = {}, pathContext = {}, ...props }) => {
    const liveData = usePreviewData(data);
  if (!(liveData?.prismicAuthor?.data)) return <></>;
  if(!liveData.prismicPost.data.author.document){
    return <p>Autor não definido</p>
  }
  if (!liveData.prismicPost.data.cover.gatsbyImageData) {
    return <p>Cover não definida</p>;
  }
  if (!liveData.prismicPost.data.keywords) {
    return <p>Keywords não definidas</p>;
  }
    let minuts = Math.round(
      ReadingTime(liveData.prismicPost.data.content.text)
    );

  return (
    <>
      <Layout
        seoComp={
          <Seo
            type={`article`}
            image={liveData.prismicPost.data.cover.url}
            description={liveData.prismicPost.data.description.text}
            keywords={[
              ...(liveData.prismicPost.data.keywords.map((k) => k.keyword) ||
                []),
              `${liveData.prismicPost.data.title}`,
            ]}
            title={liveData.prismicPost.data.title.text}
            site={location.href}
            author={`${liveData.prismicPost.data.author.document.data.name} - ${location.href}/${liveData.prismicPost.data.author.document.data.internallink}`}
          />
        }
      >
        <Header className="fgColor" frontPage={false} showHeader={false} />
        <Fade top>
          <Share
            floating={true}
            socialConfig={{
              config: {
                url: `${location.href}/`,
                title: `${liveData.prismicPost.data.title}`,
              },
            }}
          />
        </Fade>
        <header className="masthead postContainer">
          <div className="bg">
            {liveData.prismicPost.data.cover ? (
              <GatsbyImage
                Tag="div"
                alt={`${liveData.prismicPost.data.title} ${liveData.prismicPost.data.description}`}
                image={[
                  `linear-gradient(to bottom, rgba(22, 22, 22, 0.5) 0%, rgba(22, 22, 22, 0.5) 50%, rgba(22, 22, 22, 0.5) 100%)`,
                  liveData.prismicPost.data.cover.asset,
                ]}
                className={'bgPost'}
              >
                <div className="container d-flex h-100 align-items-center">
                  <div className="mx-auto text-center">
                    <h1 className="mx-auto my-0 heading">
                      {liveData.prismicPost.data.title.text}
                    </h1>
                    <h2 className="mx-auto mt-2 mb-5">
                      {moment(liveData.prismicPost.data.date).format('LL')}
                      <div className="mx-auto author">
                        por:{' '}
                        <Link
                          to={`/${liveData.prismicPost.data.author.document.data.internallink
                            .replace(/ /g, '-')
                            .toLowerCase()}/`}
                        >
                          {liveData.prismicPost.data.author.document.data.name}
                        </Link>
                      </div>
                      <div className=" author">
                        <FontAwesomeIcon icon={'clock'} />{' '}
                        {minuts > 1 ? `${minuts} minutos` : '1 minuto'}
                      </div>
                    </h2>
                    <div className="mx-auto mt-2 mb-5 share">
                      <Share
                        socialConfig={{
                          config: {
                            url: `${location.href}/` /*${socialConfigss.internallink}*/,
                            title: `${liveData.prismicPost.data.title}`,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </GatsbyImage>
            ) : (
              <div className="no-image"></div>
            )}
          </div>
        </header>

        <div className="site-container blog-post">
          <div className="container h-100 align-items-center">
            <div className="row d-flex justify-content-center">
              <div className="details col pt-5 pl-5 pr-5">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: liveData.prismicPost.data.content.html,
                    }}
                  />
              </div>
            </div>
            <div className="row">
              <Fade bottom>
                <div className="writtenBy author text-center col-sm-12">
                  Escrito por{' '}
                  <Link
                    to={`/${liveData.prismicPost.data.author.document.data.internallink
                      .replace(/ /g, '-')
                      .toLowerCase()}/`}
                  >
                    {liveData.prismicPost.data.author.document.data.name}
                  </Link>{' '}
                  <FontAwesomeIcon icon={'heart'} />{' '}
                  {moment(liveData.prismicPost.data.date).format('LL')}
                </div>
              </Fade>
              {liveData.prismicPost.data.author.document.data.customer &&
                liveData.prismicPost.data.author.document.data.listed && (
                  <Fade bottom>
                    <div className="writtenBy text-center col-sm-12">
                      <a
                        href={`/${liveData.prismicPost.data.author.document.data.internallink}/`}
                        className="btn btn-cta"
                      >
                        Marcar Consulta
                      </a>
                    </div>
                  </Fade>
                )}
            </div>

            <div className="row">
              <div className="socialMediaContent text-center col-sm-12">
                <h4>
                  "Se ajudar uma pessoa por dia, não sabe o bem que lhe faria!"
                </h4>
                <h4>Partilhe este artigo com alguém que precise :)</h4>
                <Share
                  socialConfig={{
                    config: {
                      url: `${location.href}/` /*${socialConfigss.internallink}*/,
                      title: `${liveData.prismicPost.data.title}`,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <Contact
            bg="light"
            title="Junte-se à nossa comunidade..."
            subtitle="...e receba os nossos melhores conteúdos em primeira mão"
            capture={true}
          />
          <div className="container h-100 align-items-center">
            <div className="row">
              <div className="relatedArticles col-sm-12 text-center">
                <h2 className="title">Vamos contínuar a viagem...</h2>
                <h4 className="subtitle">Também recomendamos estes artigos</h4>
                <SimilarArticles
                  keywords={liveData.prismicPost.data.keywords.map((k) =>
                    k.keyword.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                  )}
                  slug={pathContext.slug}
                  displaySomething={true}
                />
              </div>
            </div>
          </div>
        </div>
        <SocialLinks />
        <Footer />
      </Layout>
    </>
  );
};

export default PostTemplate;

export const postQuery = graphql`
  query PostQuery($id: String!) {
    prismicPost(uid: { eq: $id }) {
      data {
        slug
        title {
          text
        }
        published
        category
        date
        author {
          document {
            ... on PrismicAuthor {
              type
              data {
                name
                listed
                customer
                internallink
              }
            }
          }
        }
        keywords {
          keyword
        }
        description {
          html
          text
        }
        content {
          html
          text
        }
        cover {
          url
          gatsbyImageData
        }
      }
    }
  }
`;
